
import { NavigationEnum } from '@jameel/core/enums/';
import { AuthCaptainGuard } from '@jameel/core/guards/auth-captain.guard';
import { ErrorGuard } from '@jameel/core/guards/error.guard';

import { LoginCaptainComponent } from './authentication/login-captain/login-captain.component';

import { AuthAdminLimitedGuard } from './core/guards/auth-admin-limited.guard';
import { AuthAdminLimitedFinanceGuard } from './core/guards/auth-admin-limited-finance.guard';
import { AuthAdminLimitedCaptainGuard } from './core/guards/auth-admin-limited-captain.guard';
import { AuthAdminLimitedJameelEyeGuard } from './core/guards/auth-admin-limited-jameelEye.guard';
import { AuthAdminLimitedCustomerGuard } from './core/guards/auth-admin-limited-customer.guard';
import { AuthAdminLimitedTripsGuard } from './core/guards/auth-admin-limited-trips.guard';
import { AuthAdminLimitedRewardsGuard } from './core/guards/auth-admin-limited-rewards.guard'
import { AuthAdminLimitedStatisticsGuard } from './core/guards/auth-admin-limited-statistics.guard'


import { HomePageGuard } from './core/guards/home-page.guard';
import { Error404Component } from './error-pages/error404/error404.component';

import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AuthAdminGuard } from './core/guards/auth-admin.guard';

const routes: Routes = [
  {
    path: '',
    canActivate: [HomePageGuard],
    component: LoginCaptainComponent,
    pathMatch: 'full',
  },
  {
    path: NavigationEnum.Home,
    canLoad: [],
    loadChildren: () =>
      import('./features/home/home.module').then((m) => m.HomeModule),
    data: {
      breadcrumb: 'Home',
    },
  },

  {
    path: NavigationEnum.Register,
    loadChildren: () =>
      import('./features/register/register.module').then(
        (m) => m.RegisterModule,
      ),
    canLoad: [AuthCaptainGuard],
    data: {
      breadcrumb: 'Register',
    },
  },
  // {
  //   path: NavigationEnum.Trainings,
  //   loadChildren: () =>
  //     import('./features/trainings/trainings.module').then(
  //       (m) => m.TrainingsModule,
  //     ),
  //   canLoad: [AuthAdminLimitedGuard],
  //   data: {
  //     breadcrumb: 'Trainings',
  //   },
  // },
  {
    path: NavigationEnum.Finances,
    loadChildren: () =>
      import('./features/finances/finances.module').then(
        (m) => m.FinancesModule,
      ),
    canLoad: [],
    data: {
      breadcrumb: 'Finances',
    },
  },
  {
    path: NavigationEnum.Captains,
    loadChildren: () =>
      import('./features/captains/captains.module').then(
        (m) => m.CaptainsModule,
      ),
    canLoad: [],
    data: {
      breadcrumb: 'Captains',
    },
  },
  {
    path: NavigationEnum.Auth,
    loadChildren: () =>
      import('./authentication/authentication.module').then(
        (m) => m.AuthenticationModule,
      ),
  },
  {
    path: NavigationEnum.JameelEye,
    loadChildren: () =>
      import('./features/jameel-eye/jameel-eye.module').then(
        (m) => m.JameelEyeModule,
      ),
    canLoad: [],
  },
  {
    path: NavigationEnum.Zone,
    loadChildren: () =>
      import('./features/zone/zone.module').then(
        (m) => m.ZoneModule,
      ),
    canLoad: [],
  },
  {
    path: NavigationEnum.Customers,
    loadChildren: () =>
      import('./features/customers/customers.module').then(
        (m) => m.CustomersModule,
      ),
    canLoad: [],
  },
  {
    path: NavigationEnum.Trips,
    loadChildren: () =>
      import('./features/trips/trips.module').then(
        (m) => m.TripsModule,
      ),
    canLoad: [],
  },
  {
    path: NavigationEnum.Rewards,
    loadChildren: () =>
      import('./features/rewards/rewards.module').then(
        (m) => m.RewardsModule,
      ),
    canLoad: []
  },
  {
    path: NavigationEnum.LiveTrack,
    loadChildren: () =>
      import('./features/live-track/live-track.module').then(
        (m) => m.LiveTrackModule,
      ),
  },
  {
    path: NavigationEnum.Statistics,
    loadChildren: () =>
      import('./features/statistics/statistics.module').then(
        (m) => m.StatisticsModule,
      ),
    canLoad: [],
    data: {
      breadcrumb: 'Statistics',
    },
  },
  {
    path: NavigationEnum.ErrorPages,
    loadChildren: () =>
      import('./error-pages/error-pages.module').then(
        (m) => m.ErrorPagesModule,
      ),
  },
  {
    path: '**',
    component: Error404Component,
    canActivate: [ErrorGuard],
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      preloadingStrategy: PreloadAllModules,
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule { }
