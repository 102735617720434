export enum ActivityLogType {
  LoggedIn = "LoggedIn",
}

export enum ApplicationDetailsTabsTitles {
  BankInformation = "Bank information",
  CarPhotos = "Car photos",
  Contact = "Contact information",
  DrivingLicense = "Driving license",
  Groups = "Groups",
  History = "History",
  Insurance = "Insurance",
  NationalId = "National ID",
  Registration = "Registration",
  RideTypes = "Ride types",
}

export enum ApplicationStatus {
  ApprovedByMinistry = "ApprovedByMinistry",
  Completed = "Completed",
  InProgress = "InProgress",
  WaitingForApprovingByMinistry = "WaitingForApprovingByMinistry",
}

export enum DocumentType {
  DrivingLicenseFront = "DrivingLicenseFront",
  NationalId = "NationalId",
  BankAccountInformation = "BankAccountInformation",
  InsuranceDocument = "InsuranceDocument",
  RegistrationDocument = "RegistrationDocument",
  DrivingAuthorizationDocument = "DrivingAuthorizationDocument",
}

export enum CarDocumentType {
  DrivingAuthorizationDocument = "DrivingAuthorizationDocument",
  InsuranceDocument = "InsuranceDocument",
  RegistrationDocument = "RegistrationDocument",
}

export enum CommissionValueType {
  Fixed = "Fixed",
  Percentage = "Percentage",
}

export enum DayOfWeek {
  Sunday = "Sunday",
  Monday = "Monday",
  Tuesday = "Tuesday",
  Wednesday = "Wednesday",
  Thursday = "Thursday",
  Friday = "Friday",
  Saturday = "Saturday",
}

export enum DrivingLicenseType {
  LightTransport = "LightTransport",
  Private = "Private",
  PublicTaxi = "PublicTaxi",
  SmallBus = "SmallBus",
}

export enum DocumentStatus {
  Approved = "Approved",
  Expired = "Expired",
  Rejected = "Rejected",
  WaitingForApproval = "WaitingForApproval",
}

export enum InputFileActions {
  Cancel = "Cancel",
  PickFile = "Pick file",
}

export enum CaptainPaymentType {
  StcPay = "StcPay",
  IBAN = "IBAN",
  URPAY = "URPAY",
}

export enum NavigationEnum {
  CaptainPayment = "captain-payment",
  CaptainPaymentList = "captain-payment-list",
  AdditionalCar = "additional-car",
  ApplicationDetail = "application-detail",
  Applications = "applications",
  AdminNotifications = "admin-notifications",
  Auth = "auth",
  AuthConfirmOtp = `/auth/confirm-otp`,
  AuthLogin = `/auth/login`,
  AuthRegisterCaptain = `/auth/register-captain`,
  AuthResetPassword = `/auth/reset-password`,
  BankInformation = "bank-information",
  CancellationFees = "cancellation-fees",
  CancellationFeesList = "cancellation-fees-list",
  CarPhotos = "car-photos",
  CarsList = "cars-list",
  Captains = "captains",
  CaptainsDetails = "captains-details",
  CaptainDetailsTab = "details",
  CaptainBankTab = "bank",
  CaptainsPaymentsToJameel = "captains-payments-to-jameel",
  CaptainsPaymentsToJameelList = "captains-payments-to-jameel-list",
  CashLimits = "cash-limits",
  CashLimitsList = "cash-limits-list",
  Commissions = "commissions",
  CommissionsList = "commissions-list",
  Contact = "contact-information",
  CustomerPaymentsToJameel = "customer-payments-to-jameel",
  CustomerPaymentsToJameelList = "customer-payments-to-jameel-list",
  Dashboard = "dashboard",
  Default = "",
  Documents = "documents",
  DrivingLicense = "driving-license",
  Error404 = "/error-pages/404",
  Error500 = "/error-pages/500",
  ErrorPages = "error-pages",
  Finances = "finances",
  FinanceLog = "finance-log",
  FinanceLogList = "finance-log-list",
  FixVehicle = "fix-vehicle-documents",
  Groups = "groups",
  History = "history",
  Home = "home",
  Insurance = "insurance",
  DrivingAuthorization = "driving-aut",
  Login = "auth/login",
  LoginAdmin = "/auth/admin",
  Main = "/",
  MyDocuments = "my-documents",
  NationalId = "national-id",
  Profile = "profile",
  PaymentsToJameel = "payments-to-jameel",
  JameelProfits = "jameel-profits",
  PayoutToCaptain = "payout-to-captain",
  CaptainOverdueBalance = "captain-overdue-balance",
  JameelOverdueBalance = "jameel-overdue-balance",
  PaymentsToJameelList = "payments-to-jameel-list",
  PaymentsMadeToCaptains = "payments-made-to-captains",
  PaymentsMadeToCaptainsList = "payments-made-to-captains-list",
  PaymentsDueToCaptains = "payments-due-to-captains",
  PaymentsDueToCaptainsList = "payments-due-to-captains-list",
  Register = "register",
  RegisterAdditionalCar = `/register/additional-car`,
  RegisterCarPhotos = "/register/car-photos",
  RegisterDashboard = "/register/dashboard",
  RegisterDocuments = "/register/documents",
  RegisterFixVehicle = "/register/fix-vehicle-documents",
  RegisterMyDocuments = "/register/my-documents",
  RegisterTraining = "/register/training",
  Registration = "registration",
  Rewards = "rewards",
  RideTypes = "ride-types",
  Table = "table",
  Training = "training",
  Trainings = "trainings",
  Details = "details",
  TrainingsList = "trainings-list",
  Users = "users",
  IdLicence = "id-licence",
  Trips = "trips",
  ReferredCaptains = "referred-captains",
  Ratings = "ratings",
  CarDriving = "car-driving",
  CarInsurance = "car-insurance",
  PaymentHistory = "payment-history",
  BlockHistory = "block-history",
  JameelEye = "jameel-eye",
  Zone = "zone",
  Customers = "customers",
  CustomersDetails = "customers-details",
  CustomersList = "list",
  Wallet = "Wallet",
  TripDetails = "TripDetails",
  TripReceipt = "TripReceipt",
  CaptainReview = "CaptainReview",
  CustomerReview = "CustomerReview",
  TripLog = "TripLog",
  WeeklyCycleAdjustment = "weekly-cycle-adjustment",
  CancellationReport = "cancellation-report",
  CaptainBalanceAdjustment = "captain-balance-adjustment",
  IncentivesAndAdjustment = "incentives-and-adjustment",
  CaptainOnlineHours = "online-hours",
  LiveTrack = "live-track",
  CaptainPin = "captain-pin",
  ShiftManagement = "shift-management",
  Statistics = "statistics",
  IncentivesManagement = "incentives-management",
  AddIncentive = "add-incentive",
  UpdateIncentive = "update-incentive",
  CityManagement = "city-management",
  AddCity = "add-city",
  UpdateCity = "update-city",
  RideCategory = "ride-category",
}

export enum NavigationItemEnum {
  RegisterCaptain = "register-captain",
}

export enum ParentType {
  RideType = "RideType",
}

export enum PaymentMethodType {
  Cash = "Cash",
  Card = "Card",
  ApplePay = "ApplePay",
  Mada = "Mada",
}

export enum PersonalDocumentType {
  BankAccountInformation = "BankAccountInformation",
  DrivingLicenseFront = "DrivingLicenseFront",
  NationalId = "NationalId",
}

export enum QuantityFilterCondition {
  DoesNotEqual = "DoesNotEqual",
  Equal = "Equal",
  GreaterThan = "GreaterThan",
  LessThan = "LessThan",
}

export enum ValueFilterCondition {
  Contains = "Contains",
  DoesNotContain = "DoesNotContain",
  StartsWith = "StartsWith",
  EndsWith = "EndsWith",
}

export enum StoreFeatureNameEnum {
  CaptainPayment = "CaptainPayment",
  CaptainCars = "captainCars",
  Captains = "captains",
  CaptainPinList = "CaptainPinList",
  CashLimit = "cashLimit",
  CancellationFees = "cancellationFees",
  CaptainsPaymentsToJameel = "captainsPaymentsToJameel",
  CustomerPaymentsToJameel = "customerPaymentsToJameel",
  Commissions = "commissions",
  FinanceLog = "financeLog",
  PaymentsToJameel = "paymentsToJameel",
  PaymentsMadeToCaptains = "paymentsMadeToCaptains",
  PaymentsDueToCaptains = "paymentsDueToCaptains",
  ProfileTabs = "profileTabs",
  Register = "register",
  RideTypesList = "rideTypesList",
  Trainings = "trainings",
  Users = "users",
  UserDetails = "userDetails",
  UsersList = "usersList",
  Router = "router",
  ApplicationCars = "applicationCars",
  Applications = "applications",
  TripList = "tripList",
  Customers = "customers",
  CustomersDetails = "customers-details",
  Trips = "trips",
  FraudTrips = "fraudTrips",
  TripsHistory = "tripsHistory",
  Userblockade = "userBlockade",
  SuspiciousUsers = "suspiciousUsers",
  AdminNotifications = "admin-notiifcations",
  JameelEye = "jameel-eye",
  WeeklyCycleAdjustment = "weekly-cycle-adjustment",
  JameelOverdueBalance = "JameelOverdueBalance",
  CaptainOverdueBalance = "CaptainOverdueBalance",
  CancellationReport = "cancellation-report",
  IncentivesAndAdjustment = "incentives-and-adjustment",
  Rewards = "rewards",
  RewardDetails = "RewardDetails",
  ShiftSchedule = "shift-schedule",
  TripStatistics = "trip-statistics",
  IncentiveManagment = "incentive-managment",
  CityManagment = "city-managment",
}

export enum ActionType {
  Add = "Add",
  Delete = "Delete",
  Download = "Download",
  Edit = "Edit",
  MarkAsCompleted = "MarkAsCompleted",
  MarkAsPaid = "MarkAsPaid",
  Remove = "Remove",
  Reverse = "Reverse",
  Transfer = "Transfer",
  Upload = "Upload",
  UploadKML = "UploadKML",
  ViewKML = "ViewKML",
  Select = "Select",
}

export enum LinkType {
  ApplicationDetails = "ApplicationDetails",
  BookingDetails = "BookingDetails",
  CaptainDetails = "CaptainDetails",
  CashLimitDetails = "CashLimitDetails",
  CommissionDetails = "CommissionDetails",
  CityDetails = "CityDetails",
  Comment = "Comment",
  CustomerDetails = "CustomerDetails",
  EarningPeriodDetails = "EarningPeriodDetails",
  EmployeeDetails = "EmployeeDetails",
  GroupDetails = "GroupDetails",
  InventiveDetails = "InventiveDetails",
  RecipientDetails = "RecipientDetails",
  RewardDetails = "RewardDetails",
  RideTypeDetails = "RideTypeDetails",
  TrainingDetails = "TrainingDetails",
  TrainingAttendees = "TrainingAttendees",
  TripDetails = "TripDetails",
  UserDetails = "UserDetails",
}

export enum Gender {
  Female = "Female",
  Male = "Male",
  PreferNotSay = "PreferNotSay",
}

export enum FilterKind {
  Date = "Date",
  DateRange = "DateRange",
  Enum = "Enum",
  Int = "Int",
  String = "String",
}

export enum TableValueType {
  Action = "Action",
  ActionArray = "ActionArray",
  Boolean = "Boolean",
  Date = "Date",
  Datetime = "Datetime",
  Email = "Email",
  Enum = "Enum",
  EnumArray = "EnumArray",
  ExternalLink = "ExternalLink",
  Image = "Image",
  Link = "Link",
  MixedNumeric = "MixedNumeric",
  Number = "Number",
  Percentage = "Percentage",
  PhoneNumber = "PhoneNumber",
  Price = "Price",
  String = "String",
  StringArray = "StringArray",
  Time = "Time",
  TimePeriod = "TimePeriod",
  CheckAction = "CheckAction",
}

export enum TrainingType {
  InPerson = "InPerson",
  Online = "Online",
}

export enum UserRole {
  Administrator = "Administrator",
  // Limited = "Limited",
  // LimitedCustomer = "LimitedCustomer",
  // LimitedTrips = "LimitedTrips",
  // LimitedJameelEye = "LimitedJameelEye",
  // LimitedCaptain = "LimitedCaptain",
  // LimitedFinances = "LimitedFinances",
  // LimitedApplications = "LimitedApplications",
  // LimitedNotifications = "LimitedNotifications",
  OPERATION_OP = "OPERATION_OP",
  OPERATION_SUPER = "OPERATION_SUPER",
  MARKETING_OP = "MARKETING_OP",
  MARKETING_SUPER = "MARKETING_SUPER",
  IT_OP = "IT_OP",
  IT_SUPER = "IT_SUPER",
  FINANCE_OP = "FINANCE_OP",
  FINANCE_SUPER = "FINANCE_SUPER",
}

export enum Role {
  Administrator = "Administrator",
  Limited = "Limited",
  Captain = "Captain",
  Rider = "Rider",
}

export enum NotificationTopicType {
  Captains = "Captains",
  Riders = "Riders",
  Both = "Both",
  SelectedCaptains = "Selected Captains",
  SelectedRiders = "Selected Riders",
}

export enum FlagType {
  AppErrorrIncompletedTrip = "AppErrorInCompletedTrip",
  AppErrorrCompletedTrip = "AppErrorCompletedTrip",
  FraudTrip = "FraudTrip",
  Completed = "Completed",
  Canceled = "Canceled",
  CashSelected = "CashSelected",
  Other = "Other",
}

export enum NotificationType {
  Markting = "Markting",
  Announcement = "Announcement",
}

export enum NotificationStatus {
  Success = "Success",
  Fail = "Announcement",
  Scheduled = "Scheduled",
}

export enum VehiclePhotoType {
  CarProfile = "CarProfile",
  ExteriorFront = "ExteriorFront",
  ExteriorLeft = "ExteriorLeft",
  ExteriorRear = "ExteriorRear",
  ExteriorRight = "ExteriorRight",
  InteriorFront = "InteriorFront",
  InteriorRear = "InteriorRear",
}

export enum VehicleType {
  Convertible = "Convertible",
  Coupe = "Coupe",
  Hatchback = "Hatchback",
  MiniVan = "MiniVan",
  Other = "Other",
  PickupTruck = "PickupTruck",
  Sedan = "Sedan",
  SportsCar = "SportsCar",
  StationWagon = "StationWagon",
  SUV = "SUV",
}

export enum AdminStatus {
  Active = "Active",
  Suspended = "Suspended",
}

export enum CheckCarStatus {
  Pending = "Pending",
  Denied = "Denied",
  Approved = "Approved",
}

export enum MinistryDriverAndVehicleRegistrationStatus {
  NotSent = "NotSent",
  SentToApi = "SentToApi",
  Approved = "Approved",
  NotSubmitted = "NotSubmitted",
}

export enum PlateType {
  Private = "Private",
  PublicTransport = "PublicTransport",
  PrivateTransport = "PrivateTransport",
  PublicMinibus = "PublicMinibus",
  PrivateMinibus = "PrivateMinibus",
  Taxi = "Taxi",
  HeavyEquipment = "HeavyEquipment",
  Export = "Export",
  Diplomatic = "Diplomatic",
  Motorcycle = "Motorcycle",
  Temporary = "Temporary",
}

export enum VehicleOwnershipType {
  Rental = "Rental",
}

export enum UserApplicationStatus {
  ApprovedByMinistry = "ApprovedByMinistry",
  Complete = "Completed",
  Denied = "Denied",
  InProgress = "InProgress",
  OnHold = "OnHold",
  DocMissing = "DocMissing",
  DocIncomplete = "DocIncomplete",
  WaitingForApprovingByMinistry = "WaitingForApprovingByMinistry",
}

export enum CaptainDetailsTabsTitles {
  Details = "Details",
  IdLicense = "ID & License",
  Bank = "Bank",
  Trips = "Trips",
  Wallet = "Wallet",
  PaymentHistory = "Payment History",
  Messages = "Messages",
  Ratings = "Ratings",
  BlockHistory = "Block History",
  CarDriving = "Cars driving ",
  CarInsurance = "Cars insurance ",
  Training = "Training",
  Register = "Register",
  RideTypes = "RideTypes",
}

export enum CaptainStatus {
  NotAssigned = "NotAssigned",
  OnTrip = "OnTrip",
}

export enum CustomerStatus {
  Online = "Online",
  Offline = "Offline",
}

export enum CustomerTripStatus {
  Online,
  Offline,
}

export enum CaptainTripStatus {
  Available = "Available",
  OnTrip = "OnTrip",
  Offline = "Offline",
  RideType = "RideType",
  OnWayToC = "OnWayToC",
  OnWayToD = "OnWayToD",
  AvailableAndOnTrip = "AvailableAndOnTrip",
  All = "All",
}

export enum PaymentType {
  RiderPayment = "RiderPayment",
  RiderTripCancellationFee = "RiderTripCancellationFee",
  RiderAdjustment = "RiderAdjustment",
  CaptainTripProfit = "CaptainTripProfit",
  CaptainTripCashPaymentFee = "CaptainTripCashPaymentFee",
  CaptainGuarantee = "CaptainGuarantee",
  CaptainBonus = "CaptainBonus",
  CaptainAdjustment = "CaptainAdjustment",
  CancelledFeeForCaptain = "CancelledFeeForCaptain",
  CaptainWithdrawal = "CaptainWithdrawal",
}

export enum CaptainLoginStatus {
  Active = "Active",
  Blocked = "Blocked",
}

export enum CsvExportUrl {
  TripList = "/admin/rider/trips/csv",
  RiderList = "/admin/rider/list/csv",
  TrainingList = "/admin/training/list/csv",
  CaptainList = "/admin/captain/list/csv",
  ApplicationList = "/admin/application/list/csv",
  ApplicationAllList = "/admin/application/Alllist/csv",
  WeeklyCycleAdjustmentList = "/finance/data/list/csv",
  CancellationReportList = "/finance/data/CancellationReportlist/csv",
  JameelOverdueBalanceList = "/finance/data/jameel-overdue-balance/csv",
  JameelOverdueBalanceTotalExportList = "/finance/data/jameel-overdue-balance-export-total/csv",
  PaymentsToJameelExportList = "/finance/data/payments-to-jameel/csv",
  PaymentsToJameelExportThreeMonthList = "/finance/data/payments-to-jameel-export-three-mounth/csv",
  RewardAllList = "/admin/Reward/Allreward/csv",
  CaptainOverdueBalanceReportList = "/finance/data/captain-overdue-balance/csv",
}

export enum TripStatus {
  Created = "Created",
  Confirmed = "Confirmed",
  CaptainFoundAndAssigned = "CaptainFoundAndAssigned",
  CaptainWaitingForRiderAtPickup = "CaptainWaitingForRiderAtPickup",
  EnRoute = "EnRoute",
  EnRoutePastDropOff = "EnRoutePastDropOff",
  ArrivedAtDestination = "ArrivedAtDestination",
  CashCollected = "CashCollected",
  Paid = "Paid",
  Completed = "Completed",
  Cancelled = "Cancelled",
  Deleted = "Deleted",
}

export enum RideRequestStatus {
  Created = "Created",
  Sent = "Sent",
  Accepted = "Accepted",
  Declined = "Declined",
  Expired = "Expired",
}

export enum TripLocationSourceType {
  UserSavedLocation = "UserSavedLocation",
  Autocomplete = "Autocomplete",
  GeographicalLocationCoordinates = "GeographicalLocationCoordinates",
}

export enum TripLocationType {
  Origin = "Origin",
  Destination = "Destination",
  DropOff = "DropOff",
}

export enum UserState {
  Offline = "Offline",
  Online = "Online",
}

export enum BlockadeType {
  RiderCancellation = "RiderCancellation",
  CaptainCancellation = "CaptainCancellation",
  CaptainPayToJameel = "CaptainPayToJameel",
  UserAdminTemporary = "UserAdminTemporary",
  UserAdminPermanent = "UserAdminPermanent",
}

export enum BalanceAdjustmentType {
  CaptainGuarantee = "CaptainGuarantee",
  CaptainBonus = "CaptainBonus",
  CaptainAdjustment = "CaptainAdjustment",
  RiderAdjustment = "RiderAdjustment",
  FraudTripAdjustment = "FraudTripAdjustment",
}

export enum SystemTripCancellationReasonType {
  TotalSearchingTimeLimitReached = "TotalSearchingTimeLimitReached",
  SearchingTimeLimitReached = "SearchingTimeLimitReached",
  NoMoreCaptainsFound = "NoMoreCaptainsFound",
  CannotSetEstimatedCostBlockade = "CannotSetEstimatedCostBlockade",
}

export enum TripCancellationTriggerType {
  System = "System",
  User = "User",
}

export enum UserTripCancellationReasonType {
  DriverWaitedTooLong = "DriverWaitedTooLong",
  Custom = "Custom",
}

export enum DiscountType {
  Money = "Money",
  Percentage = "Percentage",
}

export enum UserPrivilages {
  CanViewUsersPage = "canViewUsersPage",
  CanAddNewUser = "canAddNewUser",
  CanEditUser = "canEditUser",
  CanViewApplicationPage = "canViewApplicationPage",
  CanAddLimoCompany = "canAddLimoCompany",
  CanExportApplicationToCSV = "canExportApplicationToCSV",
  CanViewApplicationDetials = "canViewApplicationDetials",
  CanSendNotification = "canSendNotification",
  CanViewNotificationPage = "canViewNotificationPage",
  CanViewCaptainPage = "canViewCaptainPage",
  CanViewShiftMangPage = "canViewShiftMangPage",
  CanEditShiftMang = "canEditShiftMang",
  CanAddShiftMang = "canAddShiftMang",
  CanExpotCaptainToCSV = "canExpotCaptainToCSV",
  CanViewCaptainDetailsPage = "canViewCaptainDetailsPage",
  CanEditCaptainRecord = "canEditCaptainRecord",
  CanViewCaptainApplication = "canViewCaptainApplication",
  CanChangeCaptainRiderStatus = "canChangeCaptainRiderStatus",
  CanAdjustCaptainBalance = "canAdjustCaptainBalance",
  CanViewAdjustedHistory = "canViewAdjustedHistory",
  CanUnBlockCaptainRider = "canUnBlockCaptainRider",
  CanRemoveCaptainRider = "canRemoveCaptainRider",
  CanBookmarkCaptainp = "canBookmarkCaptainp",
  CanViewJameelEyePage = "canViewJameelEyePage",
  CanViewCustomerPage = "canViewCustomerPage",
  CanExportCusToCSV = "canExportCusToCSV",
  CanViewCustomerDetials = "canViewCustomerDetials",
  CanAdjustCustomrtBalane = "canAdjustCustomrtBalane",
  CanViewTripPage = "canViewTripPage",
  CanViewTripDetialsPage = "canViewTripDetialsPage",
  CanChangeTripStatus = "canChangeTripStatus",
  CanViewZonesPage = "canViewZonesPage",
  CanEditZone = "canEditZone",
  CanAddZone = "canAddZone",
  CanRemoveZone = "canRemoveZone",
  CanViewRewardsPage = "canViewRewardsPage",
  CanAddNewReward = "canAddNewReward",
  CanEditReward = "canEditReward",
  CanExportRewardToCSV = "canExportRewardToCSV",
  CanViewStatPage = "canViewStatPage",
  CanViewIncentivePage = "canViewIncentivePage",
  CanAddNewIncentive = "canAddNewIncentive",
  CanEditUserGroup = "canEditUserGroup",
  CanViewFraudPage = "canViewFraudPage",
  CanViewFinancePage = "canViewFinancePage",
  CanViewPaymentsToJameel = "canViewPaymentsToJameel",
  CanExportPaymentToJameel = "canExportPaymentToJameel",
  CanViewCaptainPaymentsToJameel = "canViewCaptainPaymentsToJameel",
  CanViewCustomerPaymentsToJameel = "canViewCustomerPaymentsToJameel",
  CanViewCommissions = "canViewCommissions",
  CanAddNewCommission = "canAddNewCommission",
  CanDeleteCommission = "canDeleteCommission",
  CanViewCancellationFee = "canViewCancellationFee",
  CanViewCashLimit = "canViewCashLimit",
  CanAddCashLimit = "canAddCashLimit",
  CanDeleteCashLimit = "canDeleteCashLimit",
  CanViewFinanceLog = "canViewFinanceLog",
  CanViewWeeklyCycle = "canViewWeeklyCycle",
  CanExportWeeklyCycle = "canExportWeeklyCycle",
  CanViewCancellationReport = "canViewCancellationReport",
  CanExportCancellationReport = "canExportCancellationReport",
  CanViewPayoutsToCaptain = "canViewPayoutsToCaptain",
  CanViewJameelOverDueBalance = "canViewJameelOverDueBalance",
  CanUploadPayout = "canUploadPayout",
  CanExportPayout = "canExportPayout",
  CanExportTotalAmount = "canExportTotalAmount",
  CanAddPaymentRecord = "canAddPaymentRecord",
  CanViewIncentivesCaptainAdj = "canViewIncentivesCaptainAdj",
  CanAddNewIncAdj = "canAddNewIncAdj",
  CanViewJameelProfits = "canViewJameelProfits",
  CanViewCaptainOverDueBalance = "canViewCaptainOverDueBalance",
  CanExportTripToCSV = "canExportTripToCSV",
}
